import React, { Component } from "react";
import "./NewHeader.css";
import Logo from "../../assets/images/logo/logo-02.png";
import Icon from "../../assets/images/favicon.ico";
import BannerTabNav from "../BannerTabNav";
import { BrowserRouter, Link, Switch, Route, Redirect } from "react-router-dom";
import {
  setCustomerLogin,
  getGeoInfo,
  getCustomerLogin,
  LoginConfirmation,
} from "../axios";
import Modal from "react-modal";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import moment from "moment";
import SignUp from "../SignUp";

import { Base64 } from "js-base64";
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
class NewHeader extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);

    const { cookies } = props;
    this.state = {
      nav: false,
      modalIsOpen: false,
      ChangeLoginScreenClass: "containerLogin",
      facebookLogin: false,
      facebookResponse: {},
      GoogleLoginButton: false,
      GoogleResponse: {},
      country: "",
      country_code: "",
      currency: "",
      ip_address: "",
      customerEmail: cookies.get("userDetails") || "",
      customerEmailForData: localStorage.getItem("userDetails") || "abc",
      customerDetails: {},
      profileNew: false,
      profileNewAgain: false,
      LoginEmail: "",
      LoginPassword: "",
      errorPassword: false,
      ForgotEmail: "",
      errorForgotEmail: false,
      EmailError: false,
      PasswordLoginError:false,
      ForgotEmailError:false
    };
  }

  handleScroll = () => {
    if (this.props.page == "inner") {
      if (window.pageYOffset > 60) {
        if (!this.state.nav) {
          this.setState({ nav: true });
        }
      } else {
        if (this.state.nav) {
          this.setState({ nav: false });
        }
      }
    } else if (this.props.page == "inner_listing") {
      if (window.pageYOffset > 60) {
        if (!this.state.nav) {
          this.setState({ nav: true });
        }
      } else {
        if (this.state.nav) {
          this.setState({ nav: false });
        }
      }
    }
  };
  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  ChangeLoginEmail = (event) => {
   // this.setState({ LoginEmail: event.target.value });
   const email = validateEmail(event.target.value);
   if (email == true) {
    this.setState({ LoginEmail: event.target.value });
     this.setState({ EmailError: false });
   } else {
     this.setState({ LoginEmail: event.target.value });
    this.setState({ EmailError: true });
   }
  };

  ChangeLoginPassword = (event) => {
    //this.setState({ LoginPassword: event.target.value });
    const password = event.target.value;
    if (password.length > 5 && password.length < 15) {
      this.setState({ LoginPassword: event.target.value });
      this.setState({ PasswordLoginError: false });
    } else {
      this.setState({ LoginPassword: event.target.value });
      this.setState({ PasswordLoginError: true });
    }
  };

  LoginConfirm = (e) => {
    e.preventDefault();
   // this.setState({ errorPassword: false });
    // let emailNew = this.state.LoginEmail;
    // let email = "";
    // if (emailNew.length > 0) {
    //   email = emailNew;
    // } else {
    //   email = this.state.customerEmail.email;
    // }
    // let password = this.state.LoginPassword;

    let CheckEmailError = false;
    let CheckPasswordLoginError = false;
    this.setState({ EmailError: false });
    this.setState({ PasswordLoginError: false });
    this.setState({ errorPassword: false });
     let emailNew = this.state.LoginEmail;
   
    const email = validateEmail(emailNew);
    let emailNewData ='';
    if (email == true) {
      emailNewData = emailNew;
      this.setState({ EmailError: false });
      CheckEmailError = true;
    } else {
      CheckEmailError = false;
      this.setState({ EmailError: true });
    }

    let password = this.state.LoginPassword;
 
    if (password.length > 5 && password.length < 15) {
      CheckPasswordLoginError = true;
     
     this.setState({ PasswordLoginError: false });
    } else {
      CheckPasswordLoginError = false;
     
      this.setState({ PasswordLoginError: true });
    }
    if (
      CheckEmailError == true &&
      CheckPasswordLoginError == true 
    ) {
    

    LoginConfirmation(emailNewData, password).then(
      (data) => {
      
        if (data == "not_correct") {
          this.setState({ errorPassword: true });
        } else {
          this.setState({ customerDetails: data });

          let Verified = "";
          if (data.verified == 1) {
            Verified = true;
          }

          const dataSearch = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            country_code: data.country_code,
            verified: Verified,
            uid: data.id,
          };

          let JsonData = JSON.stringify(dataSearch);

          localStorage.setItem("userDetails", JsonData);
          window.location.reload(true);
        }
      },
      (error) => {}
    );
    }
  };

  ChangeLoginScreen = () => {
    this.setState({
      ChangeLoginScreenClass: "containerLogin right-panel-active",
    });
  };
  ChangeLoginReverse = () => {
    this.setState({ ChangeLoginScreenClass: "containerLogin" });
  };
  componentDidMount() {
    let userData = localStorage.getItem("userDetails");
  
    getGeoInfo().then(
      (data) => {
     
        this.setState({ country: data.country_name });
        this.setState({ country_code: data.country_calling_code });
        this.setState({ currency: data.currency });
        this.setState({ ip_address: data.ip });
      },
      (error) => {}
    );

    if (userData != null) {
      const { cookies } = this.props;

      let newData = JSON.parse(userData);
      getCustomerLogin(newData.email).then(
        (data) => {
         
          this.setState({ customerDetails: data });

          let Verified = "";
          if (data.verified == 1) {
            Verified = true;
          }

          const dataSearch = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            country_code: data.country_code,
            verified: Verified,
            uid: data.id,
          };

          let JsonData = JSON.stringify(dataSearch);

          localStorage.setItem("userDetails", JsonData);
        },
        (error) => {}
      );
    }

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("click", this.globalClickListener);
  }

  globalClickListener = (nativeEvent) => {
    this.setState({ profileNew: false, profileNewAgain: false }, () => {
     
      // document.removeEventListener('click', this.globalClickListener)
      window.removeEventListener("click", this.globalClickListener);
    });
  };

  OpenProfilelinkAgain = (syntheticEvent) => {
   
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ profileNewAgain: !prevState.profileNewAgain }),
      () => {
        if (this.state.profileNewAgain) {
          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    
  };

  LoginFaceBook = () => {
   
    this.setState({ facebookLogin: true });
  };

  responseFacebook = (response) => {
    const { cookies } = this.props;
   
    if (response.accessToken) {
      this.setState({ facebookResponse: response });
      let Name = response.first_name;

      let first_name = response.first_name;
      let last_name = response.last_name;
      let email = response.email;
      let profile_image = response.picture.data.url;
      let birthday = moment(response.birthday).format("Y-MM-DD");
      let gender = response.gender;
      let login_source = 1;

      let mobile_number = "";
      let country = this.state.country;
      let country_code = this.state.country_code;
      let currency = this.state.currency;
      let ip_address = this.state.ip_address;
      let password = "";
      let verified = 1;

      let Verified = "";
      if (verified == 1) {
        Verified = true;
      }

      const dataSearch = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        country_code: country_code,
        verified: Verified,
        uid: "",
      };

      let JsonData = JSON.stringify(dataSearch);

      localStorage.setItem("userDetails", JsonData);
      cookies.set("userDetails", JsonData, { path: "/" });

      setCustomerLogin(
        first_name,
        last_name,
        email,
        profile_image,
        birthday,
        gender,
        login_source,
        mobile_number,
        country,
        country_code,
        currency,
        ip_address,
        password,
        verified
      ).then(
        (data) => {
        
          if (data.id > 0) {
            window.location.reload(true);
          }
        },
        (error) => {}
      );
      this.setState({ modalIsOpen: false });
      // window.location.reload(true);
    }
  };

  responseGoogle = (response) => {
    if (response.accessToken) {
      const { cookies } = this.props;
      this.setState({ GoogleResponse: response });

      let first_name = response.profileObj.givenName;
      let last_name = response.profileObj.familyName;
      let email = response.profileObj.email;
      let profile_image = response.profileObj.imageUrl;
      let birthday = "";
      let gender = "";
      let login_source = 2;

      let mobile_number = "";
      let country = this.state.country;
      let country_code = this.state.country_code;
      let currency = this.state.currency;
      let ip_address = this.state.ip_address;
      let password = "";
      let verified = 1;

      let Verified = "";
      if (verified == 1) {
        Verified = true;
      }

      const dataSearch = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        country_code: country_code,
        verified: Verified,
        uid: "",
      };

      let JsonData = JSON.stringify(dataSearch);

      localStorage.setItem("userDetails", JsonData);
      cookies.set("userDetails", JsonData, { path: "/" });

      setCustomerLogin(
        first_name,
        last_name,
        email,
        profile_image,
        birthday,
        gender,
        login_source,
        mobile_number,
        country,
        country_code,
        currency,
        ip_address,
        password,
        verified
      ).then(
        (data) => {
        
          if (data.id > 0) {
            window.location.reload(true);
          }
        },
        (error) => {}
      );
      this.setState({ modalIsOpen: false });
      // window.location.reload(true);
    }
  };

  logoutChange = () => {
    localStorage.clear();
    window.location.reload(true);
  };
  render() {
    return (
      <>
        <div
          className={`chHeaderWrapper ${
            this.state.nav == true ? " Nav" : " stickyHeader"
          }`}
          style={{ animation: "slideInDown 0.00s linear" }}
        >
          <div className="chHeaderContainer">
            <span className="logoContainer">
              <a className="chMmtLogo" href="/">
                <img
                  src="images/logo/seyLogo.png"
                  alt="Holiday Seychelles"
                  style={{ width: 100 }}
                />
              </a>
            </span>

            {/* <BannerTabNav

                                        tabs={['Accommodations', 'Attractions & Things to Do', 'Packages', 'Land & Ferry Transfers', 'Liveaboard']}
 
                                        selected={this.props.Location}>
                                    </BannerTabNav> */}
            <nav>
              <ul className="hsFlex_Bann font12">
                <li className="menu_Hotels">
                  <a
                    href={"/?t=" + Base64.btoa(1)}
                    smooth={true}
                    className=" hsFlex_Bann hsCenter column"
                  >
                    <span className="chNavIcon appendBottom2 chSprite chHotels "></span>
                    <span
                      className={`chNavText darkGreyText ${
                        this.props.tabName === 1 ? " active" : " "
                      }`}
                    >
                      {" "}
                      Accommodations{" "}
                    </span>
                  </a>
                </li>
                <li className="menu_Villasmore">
                  <a
                    href={"/?t=" + Base64.btoa(2)}
                    smooth={true}
                    className="hsFlex_Bann hsCenter column"
                  >
                    <span className="chNavIcon appendBottom2 chSprite chHomestays"></span>
                    <span
                      className={`chNavText darkGreyText ${
                        this.props.tabName === 2 ? " active" : " "
                      }`}
                    >
                      {" "}
                      Attractions &amp; Things to Do
                    </span>
                  </a>
                </li>

                <li className="menu_Holidays">
                  <a
                    href={"/?t=" + Base64.btoa(3)}
                    className="hsFlex_Bann hsCenter column"
                  >
                    <span className="chNavIcon appendBottom2 chSprite chHolidays"></span>
                    <span
                      className={`chNavText darkGreyText ${
                        this.props.tabName === 3 ? " active" : " "
                      }`}
                    >
                      {" "}
                      Packages{" "}
                    </span>
                  </a>
                </li>

                <li className="menu_Trains">
                  <a
                    href={"/?t=" + Base64.btoa(4)}
                    className="hsFlex_Bann hsCenter column"
                  >
                    <span className="chNavIcon appendBottom2 chSprite chTrains"></span>
                    <span
                      className={`chNavText darkGreyText ${
                        this.props.tabName === 4 ? " active" : " "
                      }`}
                    >
                      {" "}
                      Land & Ferry Transfers{" "}
                    </span>
                  </a>
                </li>

                <li className="menu_Buses">
                  <a
                    href={"/?t=" + Base64.btoa(5)}
                    className="hsFlex_Bann hsCenter column"
                  >
                    <span className="chNavIcon appendBottom2 chBuses"></span>
                    <span
                      className={`chNavText darkGreyText ${
                        this.props.tabName === 5 ? " active" : " "
                      }`}
                    >
                      {" "}
                      Liveaboard{" "}
                    </span>
                  </a>
                </li>
              </ul>
            </nav>

            <ul className="userSection pushRight">
              {/* <li className="hsFlex_Bann hsCenter lhMyWallet"><span className="landingSprite walletBlanceIcon appendRight10">&nbsp;</span><span></span>
                            </li> */}
              {this.state.customerEmailForData == "abc" ? (
                <li
                  className="hsFlex_Bann hsCenter font10 hsRelative lhUser"
                  onClick={this.openModal}
                >
                  <div className="hsFlex_Bann column flexOne latoBold">
                    <p className="appendBottom3_Header">
                      {" "}
                      <img
                        src="images/icon/favicon.ico"
                        className="loginIcon"
                      />{" "}
                      Login or Create Account
                    </p>
                  </div>
                </li>
              ) : (
                <li
                  className="hs-LoginFlex hsCenter font10 hsRelative hsUser userLoggedIn"
                  onClick={this.OpenProfilelinkAgain}
                >
                  {this.state.customerDetails.profile_pic == "" ? (
                    <span className="userNameIcon whiteText hs-LoginFlex perfectCenter latoBlack appendRight10">
                      <span>
                        {this.state.customerDetails?.first_name?.charAt(0)}
                      </span>
                    </span>
                   ) : (
                    // <span className="userNameImage  appendRight10">
                    //   <img
                    //     style={{ width: "100%", borderRadius: "100%" }}
                    //     src={this.state.customerDetails.profile_pic}
                    //   />
                    // </span>
                    <span className="userNameIcon whiteText hs-LoginFlex perfectCenter latoBlack appendRight10">
                      <span>
                        {this.state.customerDetails?.first_name?.charAt(0)}
                      </span>
                    </span>
                  )} 
                  <div className="hs-LoginFlex column userNameText  latoBold">
                    <p
                      data-cy="loggedInUser"
                      className="appendBottom3 truncate customerName"
                    >
                      Hey {this.state.customerDetails.first_name}
                    </p>
                  </div>
                  {this.state.profileNewAgain && (
                    <div className="userDropdown fadeInDown hs-LoginFlex column dropbanner">
                      <a className="userItems hs-LoginFlex" href="/PlanTrips">
                        <span className="landingSprite userItemMyTrips appendRight20"></span>
                        <div>
                          <p className="font16 latoBold blackText appendBottom5">
                            My Trips
                          </p>
                          <p className="font12 lineHeight16 blackText">
                            Plan Trips and Manage Bookings
                          </p>
                        </div>
                      </a>
                      {/* <a className="userItems hs-LoginFlex" href="/ExpreriancePlanTrips" target="_blank">
                                                <span className="landingSprite userItemMyTrips appendRight20"></span>
                                                <div>
                                                    <p className="font16 latoBold blackText appendBottom5">My Expreriance Trips</p>
                                                    <p className="font12 lineHeight16 blackText">See booking details, Expreriance planned your own trip, Cancel Booking &amp; more.</p>
                                                </div>
                                            </a> */}

                      {/* <a className="userItems hs-LoginFlex" href="/Wallet" target="_blank">
                                                <span className="landingSprite userItemMyWallet appendRight20"></span>
                                                <div>
                                                    <p className="font16 latoBold blackText appendBottom5 hs-LoginFlex hsCenter">My Wallet
                                                <span className="myWalletTag font12 whiteText appendLeft5 latoBold"> ₹ 0</span>
                                                    </p>

                                                    <p className="font12 lineHeight16 blackText">Use your wallet money to avail even greater discounts</p>
                                                </div></a> */}

                      <a className="userItems hs-LoginFlex" href="/Profile">
                        <span className="landingSprite userItemMyProfile appendRight20"></span>
                        <div>
                          <p className="font16 latoBold blackText appendBottom5 hs-LoginFlex hsCenter">
                            My Profile
                          </p>
                          <p className="font12 lineHeight16 blackText">
                            Manage your profile, login details and password
                          </p>
                        </div>
                      </a>
                      <a
                        className="userItems hs-LoginFlex appendBottom10"
                        onClick={this.logoutChange}
                      >
                        <span className="landingSprite userItemLogout appendRight20"></span>
                        <div>
                          <p className="font16 latoBold blackText appendBottom5 hs-LoginFlex hsCenter">
                            Logout
                          </p>
                        </div>
                      </a>
                    </div>
                  )}
                </li>
              )}
            </ul>
          </div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={this.customStyles}
            contentLabel="Example Modal"
          >
            <div className={this.state.ChangeLoginScreenClass}>
              <div className="form-container sign-up-container">
                <form className="FormCon">
                  <h1>Create Account</h1>
                  <SignUp
                    country_code={this.state.country_code}
                    currency={this.state.currency}
                    ip_address={this.state.ip_address}
                    country={this.state.country}
                  />
                </form>
              </div>
              {this.state.facebookLogin && (
                <FacebookLogin
                  //appId="426993597895758"
                   appId="1194539465165185"
                  autoLoad={true}
                  fields="first_name, last_name, email, picture, birthday,gender"
                  scope="public_profile, email, user_birthday,user_gender"
                  returnScopes={true}
                  callback={this.responseFacebook}
                />
              )}
              {/* {this.state.GoogleLoginButton &&
                  
                        } */}
              <div className="form-container sign-in-container">
                <form className="FormCon" action="#">
                  <h1>LOG IN</h1>
                  {this.state.errorPassword && (
                    <p className="error"> Email or Password is not correct !</p>
                  )}
                  <input
                    type="email"
                    placeholder="Email"
                    value={
                      this.state.LoginEmail
                        ? this.state.LoginEmail
                        : ""
                    }
                    onChange={this.ChangeLoginEmail}
                  />
                   {this.state.EmailError && (
                    <p className="error">
                      {" "}
                      Please enter a valid Email ID!
                    </p>
                  )}
                  <input
                    type="password"
                    placeholder="Password"
                    value={this.state.LoginPassword}
                    onChange={this.ChangeLoginPassword}
                  />
                  {this.state.PasswordLoginError && (
                    <p className="error">
                      {" "}
                      The password that you've entered is incorrect
                    </p>
                  )}
                  <a href="#">Forgot your password?</a>
                  <button onClick={this.LoginConfirm}>LOG IN</button>
                  <div className="SocialmakeFlex socialBtnContainer vrtlCenter">
                    <div
                      className="SocialmakeFlex fbLoginBtn flexOne"
                      onClick={this.LoginFaceBook}
                    >
                      <span className=" popupSprite popupFbIcon"></span>
                      <span
                        className="font14 whiteText latoBold latoBold"
                        style={{ textTransform: "uppercase" }}
                      >
                        Login with Facebook
                      </span>
                    </div>
                  </div>
                  <div className="SocialmakeFlex socialBtnContainer vrtlCenter">
                    <div className="SocialmakeFlex googleLoginBtn flexOne">
                      <span style={{ width: "100%" }}>
                        {" "}
                        <GoogleLogin
                          clientId="445734814791-opepjrj50d5gdimufcicecofrl7rvb90.apps.googleusercontent.com"
                          buttonText="Login with Google"
                          onSuccess={this.responseGoogle}
                          onFailure={this.responseGoogle}
                          cookiePolicy={"single_host_origin"}
                        />
                      </span>
                    </div>
                  </div>
                </form>
              </div>
              <div className="overlay-container">
                <div className="overlay">
                  <div className="overlay-panel overlay-left">
                    <h1>Welcome Back!</h1>
                    <p>
                      To keep connected with us please login with your personal
                      info
                    </p>
                    <button className="ghost" onClick={this.ChangeLoginReverse}>
                      LOG IN
                    </button>
                  </div>
                  <div className="overlay-panel overlay-right">
                    <h1>Hello, Friend!</h1>
                    <p>Enter your personal details and start journey with us</p>
                    <button className="ghost" onClick={this.ChangeLoginScreen}>
                      Sign Up
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

export default withCookies(NewHeader);
