import React, { Component } from "react";
import Slider from "react-slick";
import Modal from "react-modal";
import "./Superoffers.css";
import { Base64 } from "js-base64";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  getSpecialOffersForHomeExperiences,
  getSpecialOfferEjunifer,
  Tripurl,
  SupplierUrl,
  setCustomerLogin,
  getGeoInfo,
  getCustomerLogin,
  LoginConfirmation,
  ConfirmationForgot,
} from "./axios";

import SignUp from "./SignUp";
import ReactTooltip from "react-tooltip";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
export default class Responsive extends Component {
  // static propTypes = {
  //   cookies: instanceOf(Cookies).isRequired,
  // };
  constructor(props) {
    super(props);
    const { cookies } = props;

    this.state = {
      nav: false,
      selected: "Accommodations",
      modalIsOfferOpen: false,
      OfferDetailsClass: "containerOffer",
      OfferData: props.OfferData || null,
      accomo: true,
      attract: false,
      package: false,
      transfer: false,
      liveaboard: false,
      Experiencesdata: null,
      Ejuniferdata: null,
      loginData: JSON.parse(props?.logindata) || null,

      modalIsOpen: false,
      ChangeLoginScreenClass: "containerLogin",
      ChangeForgotScreenClass: "containerForget",
      facebookLogin: false,
      facebookResponse: {},
      GoogleLoginButton: false,
      GoogleResponse: {},
      country: "",
      country_code: "",
      currency: "",
      ip_address: "",
      //customerEmail: cookies.get("userDetails") || "",
      //  customerEmailForData: localStorage.getItem("userDetails") || "abc",
      customerDetails: {},
      profileNew: false,
      profileNewAgain: false,
      LoginEmail: "",
      LoginPassword: "",
      errorPassword: false,
      modalIsSecondOpen: false,
      ForgotEmail: "",
      errorForgotEmail: false,
      EmailError: false,
      PasswordLoginError: false,
      ForgotEmailError: false,
    };
  }
  openOfferModal = () => {
    this.setState({ modalIsOfferOpen: true });
  };

  closeOfferModal = () => {
    this.setState({ modalIsOfferOpen: false });
  };

  componentDidMount() {
    getSpecialOffersForHomeExperiences().then(
      (data) => {
        this.setState({ Experiencesdata: data });
      },
      (error) => {}
    );
    getSpecialOfferEjunifer().then(
      (data) => {
        this.setState({ Ejuniferdata: data });
      },
      (error) => {}
    );

    //let userData = localStorage.getItem("userDetails");
    getGeoInfo().then(
      (data) => {
        // console.log(data);
        this.setState({ country: data.country_name });
        this.setState({ country_code: data.country_calling_code });
        this.setState({ currency: data.currency });
        this.setState({ ip_address: data.ip });
      },
      (error) => {}
    );
  }

  getSpecialOff = (data) => {
    if (data == "Accommodations") {
      this.setState({ accomo: true });
      this.setState({ liveaboard: false });
      this.setState({ transfer: false });
      this.setState({ package: false });
      this.setState({ attract: false });
    } else if (data == "Attractions") {
      this.setState({ attract: true });
      this.setState({ accomo: false });
      this.setState({ liveaboard: false });
      this.setState({ transfer: false });
      this.setState({ package: false });
    } else if (data == "Packages") {
      this.setState({ package: true });
      this.setState({ attract: false });
      this.setState({ accomo: false });
      this.setState({ liveaboard: false });
      this.setState({ transfer: false });
    } else if (data == "Transfers") {
      this.setState({ transfer: true });
      this.setState({ package: false });
      this.setState({ attract: false });
      this.setState({ accomo: false });
      this.setState({ liveaboard: false });
    } else if (data == "Liveaboard") {
      this.setState({ liveaboard: true });
      this.setState({ transfer: false });
      this.setState({ package: false });
      this.setState({ attract: false });
      this.setState({ accomo: false });
    }
  };

  // Start Login Register
  ChangeLoginEmail = (event) => {
    const email = validateEmail(event.target.value);
    if (email == true) {
      this.setState({ LoginEmail: event.target.value });
      this.setState({ EmailError: false });
    } else {
      this.setState({ LoginEmail: event.target.value });
      this.setState({ EmailError: true });
    }
  };
  ChangeLoginPassword = (event) => {
    //this.setState({ LoginPassword: event.target.value });
    const password = event.target.value;
    if (password.length > 5 && password.length < 15) {
      this.setState({ LoginPassword: event.target.value });
      this.setState({ PasswordLoginError: false });
    } else {
      this.setState({ LoginPassword: event.target.value });
      this.setState({ PasswordLoginError: true });
    }
  };

  LoginConfirm = (e) => {
    e.preventDefault();
    let CheckEmailError = false;
    let CheckPasswordLoginError = false;
    this.setState({ EmailError: false });
    this.setState({ PasswordLoginError: false });
    this.setState({ errorPassword: false });
    let emailNew = this.state.LoginEmail;

    const email = validateEmail(emailNew);
    let emailNewData = "";
    if (email == true) {
      emailNewData = emailNew;
      this.setState({ EmailError: false });
      CheckEmailError = true;
    } else {
      CheckEmailError = false;
      this.setState({ EmailError: true });
    }

    let password = this.state.LoginPassword;

    if (password.length > 5 && password.length < 15) {
      CheckPasswordLoginError = true;

      this.setState({ PasswordLoginError: false });
    } else {
      CheckPasswordLoginError = false;

      this.setState({ PasswordLoginError: true });
    }
    if (CheckEmailError == true && CheckPasswordLoginError == true) {
      LoginConfirmation(emailNewData, password).then(
        (data) => {
          // console.log(data);
          if (data == "not_correct") {
            this.setState({ errorPassword: true });
          } else {
            this.setState({ customerDetails: data });

            let Verified = "";
            if (data.verified == 1) {
              Verified = true;
            }

            const dataSearch = {
              first_name: data.first_name,
              last_name: data.last_name,
              email: data.email,
              country_code: data.country_code,
              verified: Verified,
              uid: data.id,
            };

            let JsonData = JSON.stringify(dataSearch);

            localStorage.setItem("userDetails", JsonData);
            // window.location.reload(true);
            //const url = hs_url;
            const url = `${Tripurl}/hstotriplogin/${btoa(data?.email)}`;
            window.location.replace(url);
          }
        },
        (error) => {}
      );
    }
  };

  //Ranjeet BY
  ChangeForgotEmail = (event) => {
    const email = validateEmail(event.target.value);
    if (email == true) {
      this.setState({ ForgotEmail: event.target.value });
      this.setState({ ForgotEmailError: false });
    } else {
      this.setState({ ForgotEmail: event.target.value });
      this.setState({ ForgotEmailError: true });
    }
  };

  ForgotConfirm = (e) => {
    e.preventDefault();
    let CheckEmailError = false;
    let emailNew = this.state.ForgotEmail;

    const email = validateEmail(emailNew);
    let emailNewData = "";
    if (email == true) {
      emailNewData = emailNew;
      this.setState({ ForgotEmailError: false });
      CheckEmailError = true;
    } else {
      CheckEmailError = false;
      this.setState({ ForgotEmailError: true });
    }
    // let emailNew = this.state.ForgotEmail;
    // if (validateEmail(emailNew)) {
    //   let email = "";
    //   if (emailNew.length > 0) {
    //     email = emailNew;
    //   }
    // let password = this.state.LoginPassword;

    if (CheckEmailError == true) {
      ConfirmationForgot(emailNewData).then(
        (data) => {
          // console.log(data);
          if (data == "not_correct") {
            this.setState({ errorForgotEmail: true });
          } else {
            setTimeout(() => {
              window.location.reload(true);
            }, 2000);
          }
        },
        (error) => {}
      );
    }
  };

  openModal = () => {
    this.setState({ modalIsSecondOpen: false });
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  openSecondModal = () => {
    this.setState({ modalIsOpen: false });
    this.setState({ modalIsSecondOpen: true });
  };

  closeSecondModal = () => {
    this.setState({ modalIsSecondOpen: false });
  };

  LoginFaceBook = () => {
    this.setState({ facebookLogin: true });
  };

  GoogleLoginOpen = () => {
    this.setState({ GoogleLoginButton: true });
  };

  addResponseData = (response) => {
    this.setState({ facebookResponse: response });
  };

  responseFacebook = (response) => {
    const { cookies } = this.props;

    if (response.accessToken) {
      this.setState({ facebookResponse: response });
      let Name = response.first_name;

      let first_name = response.first_name;
      let last_name = response.last_name;
      let email = response.email;
      let profile_image = response.picture.data.url;
      let birthday = moment(response.birthday).format("Y-MM-DD");
      let gender = response.gender;
      let login_source = 1;

      let mobile_number = "";
      let country = this.state.country;
      let country_code = this.state.country_code;
      let currency = this.state.currency;
      let ip_address = this.state.ip_address;
      let password = "";
      let verified = 1;

      let Verified = "";
      if (verified == 1) {
        Verified = true;
      }

      const dataSearch = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        country_code: country_code,
        verified: Verified,
        uid: "",
      };

      let JsonData = JSON.stringify(dataSearch);

      localStorage.setItem("userDetails", JsonData);
      cookies.set("userDetails", JsonData, { path: "/" });

      setCustomerLogin(
        first_name,
        last_name,
        email,
        profile_image,
        birthday,
        gender,
        login_source,
        mobile_number,
        country,
        country_code,
        currency,
        ip_address,
        password,
        verified
      ).then(
        (data) => {
          // console.log(data);
          if (data.id > 0) {
            // window.location.reload(true);
            const url = `${Tripurl}/hstotriplogin/${btoa(email)}`;
            window.location.replace(url);
          }
        },
        (error) => {}
      );
      this.setState({ modalIsOpen: false });
      //window.location.reload(true);
    }
  };

  responseGoogle = (response) => {
    // console.log(response);
    if (response.accessToken) {
      const { cookies } = this.props;
      this.setState({ GoogleResponse: response });

      let first_name = response.profileObj.givenName;
      let last_name = response.profileObj.familyName;
      let email = response.profileObj.email;
      let profile_image = response.profileObj.imageUrl;
      let birthday = "";
      let gender = "";
      let login_source = 2;

      let mobile_number = "";
      let country = this.state.country;
      let country_code = this.state.country_code;
      let currency = this.state.currency;
      let ip_address = this.state.ip_address;
      let password = "";
      let verified = 1;

      let Verified = "";
      if (verified == 1) {
        Verified = true;
      }

      const dataSearch = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        country_code: country_code,
        verified: Verified,
        uid: "",
      };

      let JsonData = JSON.stringify(dataSearch);

      localStorage.setItem("userDetails", JsonData);
      cookies.set("userDetails", JsonData, { path: "/" });

      setCustomerLogin(
        first_name,
        last_name,
        email,
        profile_image,
        birthday,
        gender,
        login_source,
        mobile_number,
        country,
        country_code,
        currency,
        ip_address,
        password,
        verified
      ).then(
        (data) => {
          // console.log(data);
          if (data.id > 0) {
            //  window.location.reload(true);
            const url = `${Tripurl}/hstotriplogin/${btoa(email)}`;
            window.location.replace(url);
          }
        },
        (error) => {}
      );
      this.setState({ modalIsOpen: false });
      // window.location.reload(true);
    }
  };
  //End Login Register

  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const getAmount = (data, Price, forMeal) => {
      const data12 = Object.entries(forMeal);
      const rateId = data12.filter(([key, value]) => value == Price);

      const Offer1 = data.filter(
        (item, key) =>
          item != "no_offer" && item.tourSpecial.rate_plan_id == rateId[0][0]
      );

      if (Offer1.length > 0) {
        let result = [];

        Offer1.map((i, j) => {
          if (i.combineoffer == "nocombine") {
            if (i.specialdata.discount_id == 2) {
              const disA = i.specialdata.rate_amount;
              result.push(+disA);
            } else {
              const disA = Price - i.specialdata.rate_amount;
              result.push(+disA);
            }
          } else {
            if (i.specialdata.discount_id == 2) {
              const disA = i.specialdata.rate_amount;
              result.push(+disA);
            } else {
              const disA = Price - i.specialdata.rate_amount;
              result.push(+disA);
            }
          }
        });

        const Amount = result.reduce((a, v) => a + v, 0);

        const balanceAmount =
          Price - (Math.round(Amount * Price) / 100).toFixed(2);
        return (
          <>
            {/* <p>
              <span className="font-weight-bold text-lowercase from-price-text">
                From{" "}
              </span>
            </p> */}
            <div className="containerFromAmount" style={{ display: "flex" }}>
              <p
                className="grayText font16 lineThrough appendBottom5"
                id="hlistpg_hotel_cut_price"
              >
                <span>€</span>
                {Price}
              </p>

              <p
                className="latoBlack font16 blackText appendBottom5 appendLeft5"
                id="hlistpg_hotel_shown_price"
              >
                <span>€</span>
                {balanceAmount.toFixed(2)}
              </p>
            </div>
            <p class="hrtlCenter appendBottom10 directionRtl">
              <span class="redText font12">
                <span>Save </span>
                <span class="latoBold" id="hlistpg_hotel_saved_price">
                  <span>€</span>
                  {(Math.round(Amount * Price) / 100).toFixed(2)}
                </span>
              </span>
              <span class="spriteNew discountIcon relative appendLeft5">
                <span
                  class="latoBold font11 whiteText discountText"
                  id="hlistpg_hotel_saved_price_percentage"
                  style={{
                    lineHeight: "15px",
                    paddingRight: "5px",
                  }}
                >
                  {parseInt(Amount)}%
                </span>
              </span>
            </p>
          </>
        );
      } else {
        return (
          <>
            {/* <p>
              <span className="font-weight-bold text-lowercase from-price-text">
                From{" "}
              </span>
            </p> */}
            <p
              className="latoBlack font16 blackText appendBottom5"
              id="hlistpg_hotel_shown_price"
            >
              <span>€</span>
              {Price}
            </p>
          </>
        );
      }
    };
    const getAmountHotel = (data) => {
      const data12 = Object.entries(data.forMeal);
      const rateId = data12.filter(([key, value]) => value == data.amount);

      const Offer1 = data.specialOffer.filter(
        (item, key) =>
          item != "no_offer" && item.roomSpecial.rate_plan_id == rateId[0][0]
      );

      if (Offer1.length > 0) {
        let result = [];

        Offer1.map((i, j) => {
          if (i.combineoffer == "nocombine") {
            if (i.specialdata.spl_offer_type_id == 1) {
              const dis =
                data.amount * parseInt(i.specialdata.pay_stay_length) -
                data.amount * parseInt(i.specialdata.pay_stay_value);

              const disA =
                (dis /
                  (data.amount * parseInt(i.specialdata.pay_stay_length))) *
                100;

              result.push(disA);
            } else {
              if (i.specialdata.discount_in == 2) {
                const disA = i.specialdata.rate_discount;
                result.push(+disA);
              } else {
                const disA = data.amount - i.specialdata.rate_discount;
                result.push(+disA);
              }
            }
          } else {
            if (i.specialdata.spl_offer_type_id == 1) {
              const dis =
                data.amount * parseInt(i.specialdata.pay_stay_length) -
                data.amount * parseInt(i.specialdata.pay_stay_value);
              const disA =
                (dis /
                  (data.amount * parseInt(i.specialdata.pay_stay_length))) *
                100;
              result.push(disA);
            } else {
              if (i.specialdata.discount_in == 2) {
                const disA = i.specialdata.rate_discount;
                result.push(+disA);
              } else {
                const disA = data.amount - i.specialdata.rate_discount;
                result.push(+disA);
              }
            }
          }
        });

        const Amount = result.reduce((a, v) => a + v, 0);

        const balanceAmount =
          data.amount - (Math.round(Amount * data.amount) / 100).toFixed(2);

        return (
          <>
            <div className="containerFromAmount" style={{ display: "flex" }}>
              <p
                className="grayText font16 lineThrough appendBottom5"
                id="hlistpg_hotel_cut_price"
              >
                <span>€</span>
                {data.amount.toFixed(2)}
              </p>
              {/* <p>
              <span className="font-weight-bold text-lowercase from-price-text">
                From{" "}
              </span>
            </p> */}
              <p
                className="latoBlack font16 blackText appendBottom5 appendLeft5"
                id="hlistpg_hotel_shown_price"
              >
                <span>€</span>
                {balanceAmount.toFixed(2)}
              </p>
            </div>
            <p class="hrtlCenter appendBottom10 directionRtl">
              <span class="redText font12">
                <span>Save </span>
                <span class="latoBold" id="hlistpg_hotel_saved_price">
                  <span>€</span>
                  {(Math.round(Amount * data.amount) / 100).toFixed(2)}
                </span>
              </span>
              <span class="spriteNew discountIcon relative appendLeft5">
                <span
                  class="latoBold font11 whiteText discountText"
                  id="hlistpg_hotel_saved_price_percentage"
                  style={{
                    lineHeight: "15px",
                    paddingRight: "5px",
                  }}
                >
                  {parseInt(Amount)}%
                </span>
              </span>
            </p>
          </>
        );
      } else {
        return (
          <>
            <p>
              <span className="font-weight-bold text-lowercase from-price-text">
                From{" "}
              </span>
            </p>
            <p
              className="latoBlack font16 blackText appendBottom5"
              id="hlistpg_hotel_shown_price"
            >
              <span>€</span>
              {data.amount.toFixed(2)}
            </p>
          </>
        );
      }
    };

    return (
      <>
        <div
          className=" appendBottom20 superOfferSection  sticky-scroll"
          id="Offers_Listing"
        >
          <div className="container">
            <div className="SuperlandingCard">
              <div className="blackText latoBold">
                <h2>Best Deals and Offers</h2>
                <div className="SuperFlex perfectCenter">
                  <ul className="SuperFlex superOffersTab">
                    <li
                      className={this.state.accomo && "active latoBlack"}
                      onClick={() => this.getSpecialOff("Accommodations")}
                    >
                      <a className="SuperFlex hrtlCenter column darkGreyText">
                        <span>
                          Accommodations (
                          {this.state.Ejuniferdata?.specialOffers != null &&
                            this.state.Ejuniferdata?.specialOffers !=
                              "no_special_offers" &&
                            this.state.Ejuniferdata?.specialOffers &&
                            this.state.Ejuniferdata?.specialOffers.length}
                          )
                        </span>
                      </a>
                    </li>
                    <li
                      className={this.state.attract && "active latoBlack"}
                      onClick={() => this.getSpecialOff("Attractions")}
                    >
                      <a className="SuperFlex hrtlCenter column darkGreyText">
                        <span>
                          {" "}
                          Attractions & Things To Do (
                          {this.state.Experiencesdata != null &&
                          this.state.Experiencesdata.specialOffersExperiences !=
                            "no_special_offers_exp"
                            ? this.state.Experiencesdata.Attractionspecialoffers.filter(
                                (item) => item[0] !== "no_Attraction_data"
                              ).length
                            : 0}
                          )
                        </span>
                      </a>
                    </li>
                    <li
                      className={this.state.package && "active latoBlack"}
                      onClick={() => this.getSpecialOff("Packages")}
                    >
                      <a className="SuperFlex hrtlCenter column darkGreyText">
                        <span>
                          {" "}
                          Packages (
                          {this.state.Experiencesdata != null &&
                          this.state.Experiencesdata.specialOffersExperiences !=
                            "no_special_offers_exp"
                            ? this.state.Experiencesdata.Packagespecialoffers.filter(
                                (item) => item[0] !== "no_package_data"
                              ).length
                            : 0}
                          )
                        </span>
                      </a>
                    </li>
                    <li
                      className={this.state.transfer && "active latoBlack"}
                      onClick={() => this.getSpecialOff("Transfers")}
                    >
                      <a className="SuperFlex hrtlCenter column darkGreyText">
                        <span>
                          {" "}
                          Land & Ferry Transfers (
                          {this.state.Experiencesdata != null &&
                          this.state.Experiencesdata.specialOffersExperiences !=
                            "no_special_offers_exp"
                            ? this.state.Experiencesdata.Transferspecialoffers.filter(
                                (item) => item[0] !== "no_transfer_data"
                              ).length
                            : 0}
                          )
                        </span>
                      </a>
                    </li>
                    <li
                      className={this.state.liveaboard && "active latoBlack"}
                      onClick={() => this.getSpecialOff("Liveaboard")}
                    >
                      <a className="SuperFlex hrtlCenter column darkGreyText">
                        <span>
                          {" "}
                          Liveaboard (
                          {this.state.Experiencesdata != null &&
                          this.state.Experiencesdata.specialOffersExperiences !=
                            "no_special_offers_exp"
                            ? this.state.Experiencesdata.LiveAboardspecialoffers.filter(
                                (item) => item[0] !== "no_liveaboard_data"
                              ).length
                            : 0}
                          )
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="SuperlandingCardSlider superOffers"
                style={{ direction: "ltr" }}
              >
                {this.state.accomo && (
                  <>
                    {this.state.Ejuniferdata == null ? (
                      <>
                        <div style={{ width: 350, margin: "0 auto" }}>
                          <img
                            src="images/noSpecialOffer.png"
                            style={{ width: "100%" }}
                          />
                          <h6 style={{ fontSize: 15, textAlign: "center" }}>
                            No Special Offer for Accommodations{" "}
                          </h6>
                        </div>
                      </>
                    ) : (
                      <Slider {...settings}>
                        {this.state.Ejuniferdata.specialOffers != null &&
                          this.state.Ejuniferdata.specialOffers !=
                            "no_special_offers" &&
                          this.state.Ejuniferdata.specialOffers.map(
                            (item, index) => {
                              let querySearch = [
                                {
                                  adultCounter: 2,
                                  childCounter: 0,
                                  roomCounter: 1,
                                  isExpanded: true,
                                },
                              ];

                              let JsonState = {
                                defaultZoneval: item.property_name,
                                startDate: moment(item.checkin_date).format(
                                  "Y-MM-DD"
                                ),
                                endDate: moment(item.checkout_date).format(
                                  "Y-MM-DD"
                                ),
                                guestCombination: querySearch,
                                accopage: 1,
                                accosize: 20,
                              };
                              const hotelArr = JSON.stringify(JsonState);
                              return (
                                <div
                                  className="slideItem"
                                  style={{ display: "inline-block" }}
                                >
                                  <div className="SuperFlex column itemCard soCardHeight pointer">
                                    <div className="SuperFlex cardInnerInfo">
                                      <div className="itemThumb SuperFlex column hrtlCenter appendRight20">
                                        <div className="itemImgWrap makeRelative">
                                          <a
                                            href={
                                              "/hotelDetails?hotelid=" +
                                              Base64.btoa(item.property_id) +
                                              "&query=" +
                                              encodeURIComponent(hotelArr)
                                            }
                                            target="_blank"
                                          >
                                            {/* <img
                                      src={
                                        item.property_gallery[0].image
                                      } */}
                                            <img
                                              src={
                                                this.state.Ejuniferdata
                                                  .specialOffersImage[index][0]
                                                  .image
                                              }
                                              alt=""
                                            />
                                          </a>
                                        </div>
                                      </div>
                                      <div className="itemDesc latoBlack">
                                        <a
                                          href={
                                            "/hotelDetails?hotelid=" +
                                            Base64.btoa(item.property_id) +
                                            "&query=" +
                                            encodeURIComponent(hotelArr)
                                          }
                                          style={{ fontSize: "14px" }}
                                          target="_blank"
                                          className="blackText font18 lineHeight22 appendBottom10 "
                                        >
                                          {item.property_name.substring(0, 30)}
                                        </a>
                                        <span className="redBdr appendBottom10">
                                          <em></em>
                                        </span>
                                        <div className="Small-Text">
                                          <p
                                            className="address"
                                            style={{
                                              color: "gray",
                                              fontSize: "10px",
                                            }}
                                          >
                                            {" "}
                                            {item.address_line_1.substring(
                                              0,
                                              40
                                            )}
                                          </p>
                                        </div>

                                        {parseInt(item.PercentageAm) == 0 ? (
                                          <>
                                            <p>
                                              <span className="font-weight-bold text-lowercase from-price-text">
                                                From{" "}
                                              </span>
                                            </p>
                                            <p
                                              className="latoBlack font16 blackText appendBottom5"
                                              id="hlistpg_hotel_shown_price"
                                            >
                                              <span>€</span>
                                              {(
                                                Math.round(item.Amount * 100) /
                                                100
                                              ).toFixed(2)}
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            <div
                                              className="containerFrom appendBottom5"
                                              style={{ display: "flex" }}
                                            >
                                              <p>
                                                <span className="font-weight-bold from-price-text">
                                                  Per Night From{" "}
                                                </span>
                                              </p>
                                            </div>
                                            <div
                                              className="containerFromAmount"
                                              style={{ display: "flex" }}
                                            >
                                              <p
                                                className="grayText font16 lineThrough appendBottom5"
                                                id="hlistpg_hotel_cut_price"
                                              >
                                                <span>€</span>
                                                {(
                                                  Math.round(
                                                    item.Amount * 100
                                                  ) / 100
                                                ).toFixed(2)}
                                              </p>
                                              <p
                                                className="latoBlack font16 blackText appendBottom5"
                                                id="hlistpg_hotel_shown_price"
                                                style={{ marginLeft: "5px" }}
                                              >
                                                <span>€</span>
                                                {(
                                                  Math.round(
                                                    item.HotelOfferAmount * 100
                                                  ) / 100
                                                ).toFixed(2)}
                                              </p>
                                            </div>
                                            <p class="hrtlCenter appendBottom10 directionRtl">
                                              <span class="redText font12">
                                                <span>Save </span>
                                                <span
                                                  class="latoBold"
                                                  id="hlistpg_hotel_saved_price"
                                                >
                                                  <span>€</span>
                                                  {(
                                                    Math.round(
                                                      item.HotelOfferAmNew * 100
                                                    ) / 100
                                                  ).toFixed(2)}
                                                </span>
                                              </span>
                                              <span class="spriteNew discountIcon relative appendLeft5">
                                                <span
                                                  class="latoBold font11 whiteText discountText"
                                                  id="hlistpg_hotel_saved_price_percentage"
                                                  style={{
                                                    lineHeight: "15px",
                                                    paddingRight: "5px",
                                                  }}
                                                >
                                                  {parseInt(item.PercentageAm)}%
                                                </span>
                                              </span>
                                            </p>
                                          </>
                                        )}
                                        {/* <p
                                          className="blackText appendBottom5"
                                          style={{ fontSize: 10}}
                                        >
                                          Per Night
                                        </p> */}
                                      </div>
                                    </div>
                                    <p
                                      className="SuperFlex hrtlCenter appendBottom10"
                                      style={{
                                        margin: "auto",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <span className="SuperFlex flexOne spaceBetween font16">
                                        <div className="Small-Text">
                                          <p
                                            className="address"
                                            style={{
                                              color: "gray",
                                              fontSize: "13px",
                                            }}
                                          >
                                            {" "}
                                            Offer Available From{" "}
                                            {moment(item.checkin_date).format(
                                              "DD MMM Y"
                                            )}{" "}
                                            *Condition Apply
                                          </p>
                                        </div>
                                      </span>
                                    </p>

                                    {/* <p className="SuperFlex hrtlCenter">
                          <span className="SuperFlex flexOne spaceBetween font13">
                            <a href={"/tourofferlisting"} target="_blank" className="latoBlack pushRight">Know More</a>
                          </span>
                        </p> */}
                                    <div class="persuasion ">
                                      <div class="persuasion__item pc__cashbackDeal">
                                        <span class="sprite infoIconGreen"></span>
                                        <span data-tip data-for={item.id}>
                                          {item.HotelOfferName.substring(0, 40)}{" "}
                                          *Offer
                                        </span>
                                      </div>

                                      <div className="customTooltip">
                                        <ReactTooltip
                                          place="right"
                                          type="light"
                                          id={item.id}
                                          className="overrideMe"
                                          data-html={true}
                                          insecure={true}
                                          multiline={true}
                                        >
                                          <div className="reactpoopup">
                                            <div className="px-0">
                                              <label
                                                for="rb0"
                                                className="mb-0 w-100 enabled"
                                              >
                                                <div
                                                  className="tour-grade-title h3 mb-0 font-weight-medium tg-title"
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  {" "}
                                                  {item.multipple_offer.replace(
                                                    ", ",
                                                    " NEw Line"
                                                  )}
                                                </div>
                                              </label>
                                            </div>
                                          </div>
                                        </ReactTooltip>
                                      </div>
                                    </div>
                                    <div class="title__placeHolder font12 pc__bottomBox"></div>
                                  </div>
                                </div>
                              );
                              //   }
                            }
                          )}
                        {this.state.OfferData != null &&
                          this.state.OfferData.specialOffers !=
                            "no_special_offers" &&
                          this.state.OfferData.data.map((item, index) => {
                            if (item != "nodata") {
                              let checkin = new Date();
                              let dateString = new Date(); // date string
                              let actualDate = new Date(dateString); // convert to actual date
                              let checkout = new Date(
                                actualDate.getFullYear(),
                                actualDate.getMonth(),
                                actualDate.getDate() + 1
                              );
                              let querySearch = [
                                {
                                  adultCounter: 1,
                                  childCounter: 0,
                                  roomCounter: 1,
                                  isExpanded: true,
                                },
                              ];

                              let JsonState = {
                                defaultZoneval: this.state.OfferData
                                  .specialOffers[index].property_name,
                                startDate: moment(checkin).format("Y-MM-DD"),
                                endDate: moment(checkout).format("Y-MM-DD"),
                                guestCombination: querySearch,
                                accopage: 1,
                                accosize: 20,
                              };
                              const hotelArr = JSON.stringify(JsonState);
                              return (
                                <div
                                  className="slideItem"
                                  style={{ display: "inline-block" }}
                                >
                                  <div className="SuperFlex column itemCard soCardHeight pointer">
                                    <div className="SuperFlex cardInnerInfo">
                                      <div className="itemThumb SuperFlex column hrtlCenter appendRight20">
                                        <div className="itemImgWrap makeRelative">
                                          <a
                                            href={
                                              "/hotelDetails?hotelid=" +
                                              Base64.btoa(
                                                this.state.OfferData
                                                  .specialOffers[index]
                                                  .property_id
                                              ) +
                                              "&query=" +
                                              encodeURIComponent(hotelArr)
                                            }
                                            target="_blank"
                                          >
                                            <img
                                              src={
                                                SupplierUrl +
                                                "/uploads/property_gallery_main/" +
                                                this.state.OfferData
                                                  .specialOffersImage[index][0]
                                                  .image
                                              }
                                              alt=""
                                            />
                                          </a>
                                        </div>
                                      </div>
                                      <div className="itemDesc latoBlack">
                                        <a
                                          href={
                                            "/hotelDetails?hotelid=" +
                                            Base64.btoa(
                                              this.state.OfferData
                                                .specialOffers[index]
                                                .property_id
                                            ) +
                                            "&query=" +
                                            encodeURIComponent(hotelArr)
                                          }
                                          style={{ fontSize: "14px" }}
                                          target="_blank"
                                          className="blackText font18 lineHeight22 appendBottom10 "
                                        >
                                          {this.state.OfferData.specialOffers[
                                            index
                                          ].property_name.substring(0, 30)}
                                        </a>
                                        <span className="redBdr appendBottom10">
                                          <em></em>
                                        </span>

                                        <div className="Small-Text">
                                          <p
                                            className="address"
                                            style={{
                                              color: "gray",
                                              fontSize: "10px",
                                            }}
                                          >
                                            {" "}
                                            {this.state.OfferData.specialOffers[
                                              index
                                            ].address_line_1.substring(0, 40)}
                                          </p>
                                        </div>

                                        {getAmountHotel(item)}
                                        <p
                                          className="blackText appendBottom5"
                                          style={{ fontSize: 10 }}
                                        >
                                          Per Night
                                        </p>
                                      </div>
                                    </div>

                                    <div class="persuasion ">
                                      <div class="persuasion__item pc__cashbackDeal">
                                        <span class="sprite infoIconGreen"></span>
                                        <span>
                                          {this.state.OfferData.specialOffers[
                                            index
                                          ].offer_name.substring(0, 40)}
                                        </span>
                                      </div>
                                    </div>
                                    <div class="title__placeHolder font12 pc__bottomBox"></div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                      </Slider>
                    )}
                  </>
                )}

                {this.state.attract && (
                  <>
                    {(this.state.Experiencesdata != null &&
                      this.state.Experiencesdata.Attractionspecialoffers ==
                        "no_Attraction_data") ||
                    this.state.Experiencesdata.specialOffersExperiences ==
                      "no_special_offers_exp" ? (
                      <>
                        <div style={{ width: 350, margin: "0 auto" }}>
                          <img
                            src="images/noSpecialOffer.png"
                            style={{ width: "100%" }}
                          />
                          <h6 style={{ fontSize: 15, textAlign: "center" }}>
                            No Special Offer for Attractions & Things To Do{" "}
                          </h6>
                        </div>
                      </>
                    ) : (
                      <Slider {...settings}>
                        {this.state?.Experiencesdata != null &&
                          this.state?.Experiencesdata
                            .specialOffersExperiences !=
                            "no_special_offers_exp" &&
                          Object.values(
                            this.state.Experiencesdata.specialOffersExperiences
                          ).map((item, index) => {
                            if (
                              this.state.Experiencesdata
                                .Attractionspecialoffers[index] !=
                              "no_Attraction_data"
                            ) {
                              const specialData = Object.values(
                                this.state.Experiencesdata
                                  .Attractionspecialoffers[index]
                              )[0][item.tour_id].specialOffer;
                              const Price = Object.values(
                                this.state.Experiencesdata
                                  .Attractionspecialoffers[index]
                              )[0][item.tour_id].tour_price;
                              const forMeal = Object.values(
                                this.state.Experiencesdata
                                  .Attractionspecialoffers[index]
                              )[0][item.tour_id].forMeal;
                              const TourData = Object.values(
                                this.state.Experiencesdata
                                  .Attractionspecialoffers[index]
                              )[0][item.tour_id].tour_data;
                              return (
                                <div
                                  className="slideItem"
                                  style={{ display: "inline-block" }}
                                >
                                  <div className="SuperFlex column itemCard soCardHeight pointer">
                                    <div className="SuperFlex cardInnerInfo">
                                      <div className="itemThumb SuperFlex column hrtlCenter appendRight20">
                                        <div className="itemImgWrap makeRelative">
                                          <a
                                            href={
                                              "/activityDetails?tourid=" +
                                              Base64.btoa(TourData?.id) +
                                              "&name=" +
                                              encodeURIComponent(
                                                TourData?.tour_name
                                              ) +
                                              "&location=" +
                                              encodeURIComponent(
                                                TourData?.tour_location
                                              ) +
                                              "&dateselected=" +
                                              encodeURIComponent(new Date())
                                            }
                                            target="_blank"
                                          >
                                            <img
                                              src={
                                                this.state.Experiencesdata
                                                  .ExperiencesImage[index][0]
                                              }
                                              alt=""
                                            />
                                          </a>
                                        </div>
                                      </div>
                                      <div className="itemDesc latoBlack">
                                        <a
                                          href={
                                            "/activityDetails?tourid=" +
                                            Base64.btoa(TourData?.id) +
                                            "&name=" +
                                            encodeURIComponent(
                                              TourData?.tour_name
                                            ) +
                                            "&location=" +
                                            encodeURIComponent(
                                              TourData?.tour_location
                                            ) +
                                            "&dateselected=" +
                                            encodeURIComponent(new Date())
                                          }
                                          style={{ fontSize: "14px" }}
                                          target="_blank"
                                          className="blackText font18 lineHeight22 appendBottom10 "
                                        >
                                          {TourData?.tour_name.substring(0, 30)}
                                        </a>
                                        <span className="redBdr appendBottom10">
                                          <em></em>
                                        </span>

                                        <div className="Small-Text">
                                          <p
                                            className="address"
                                            style={{
                                              color: "gray",
                                              fontSize: "10px",
                                            }}
                                          >
                                            {" "}
                                            {TourData?.tour_location.substring(
                                              0,
                                              40
                                            )}
                                          </p>
                                        </div>
                                        <div
                                          className="containerFrom appendBottom5"
                                          style={{ display: "flex" }}
                                        >
                                          <p>
                                            <span className="font-weight-bold from-price-text">
                                              Per Person{" "}
                                            </span>
                                          </p>
                                        </div>
                                        {getAmount(specialData, Price, forMeal)}
                                        {/* <p
                                          className="blackText appendBottom5"
                                          style={{ fontSize: 10 }}
                                        >
                                          Per Person
                                        </p> */}
                                      </div>
                                    </div>

                                    <p
                                      className="SuperFlex hrtlCenter appendBottom10"
                                      style={{
                                        margin: "auto",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <span className="SuperFlex flexOne spaceBetween font16">
                                        <div className="Small-Text">
                                          <p
                                            className="address"
                                            style={{
                                              color: "gray",
                                              fontSize: "13px",
                                            }}
                                          >
                                            {" "}
                                            Offer Available From{" "}
                                            {moment().format("DD MMM Y")}{" "}
                                            *Condition Apply
                                          </p>
                                        </div>
                                      </span>
                                    </p>

                                    <div class="persuasion ">
                                      <div class="persuasion__item pc__cashbackDeal">
                                        <span class="sprite infoIconGreen"></span>
                                        <span>
                                          {item.offer_name.substring(0, 40)}
                                        </span>
                                      </div>
                                    </div>
                                    <div class="title__placeHolder font12 pc__bottomBox"></div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                      </Slider>
                    )}
                  </>
                )}
                {this.state.package && (
                  <>
                    {(this.state.Experiencesdata != null &&
                      this.state.Experiencesdata.Packagespecialoffers ==
                        "no_package_data") ||
                    this.state.Experiencesdata.specialOffersExperiences ==
                      "no_special_offers_exp" ? (
                      <>
                        <div style={{ width: 350, margin: "0 auto" }}>
                          <img
                            src="images/noSpecialOffer.png"
                            style={{ width: "100%" }}
                          />
                          <h6 style={{ fontSize: 15, textAlign: "center" }}>
                            No Special Offer for Package{" "}
                          </h6>
                        </div>
                      </>
                    ) : (
                      <Slider {...settings}>
                        {this.state.Experiencesdata != null &&
                          this.state.Experiencesdata.specialOffersExperiences !=
                            "no_special_offers_exp" &&
                          this.state.Experiencesdata.specialOffersExperiences.map(
                            (item, index) => {
                              if (
                                this.state.Experiencesdata.Packagespecialoffers[
                                  index
                                ] != "no_package_data"
                              ) {
                                const specialData = Object.values(
                                  this.state.Experiencesdata
                                    .Packagespecialoffers[index]
                                )[0][item.tour_id].specialOffer;
                                const Price = Object.values(
                                  this.state.Experiencesdata
                                    .Packagespecialoffers[index]
                                )[0][item.tour_id].tour_price;
                                const forMeal = Object.values(
                                  this.state.Experiencesdata
                                    .Packagespecialoffers[index]
                                )[0][item.tour_id].forMeal;
                                const TourData = Object.values(
                                  this.state.Experiencesdata
                                    .Packagespecialoffers[index]
                                )[0][item.tour_id].tour_data;
                                return (
                                  <div
                                    className="slideItem"
                                    style={{ display: "inline-block" }}
                                  >
                                    <div className="SuperFlex column itemCard soCardHeight pointer">
                                      <div className="SuperFlex cardInnerInfo">
                                        <div className="itemThumb SuperFlex column hrtlCenter appendRight20">
                                          <div className="itemImgWrap makeRelative">
                                            <a
                                              href={
                                                "/packageDetails?tourid=" +
                                                Base64.btoa(TourData.id) +
                                                "&name=" +
                                                encodeURIComponent(
                                                  TourData.tour_name
                                                ) +
                                                "&location=" +
                                                encodeURIComponent(
                                                  TourData.tour_location
                                                ) +
                                                "&dateselected=" +
                                                encodeURIComponent(new Date())
                                              }
                                              target="_blank"
                                            >
                                              <img
                                                src={
                                                  this.state.Experiencesdata
                                                    .ExperiencesImage[index][0]
                                                }
                                                alt=""
                                              />
                                            </a>
                                          </div>
                                        </div>
                                        <div className="itemDesc latoBlack">
                                          <a
                                            href={
                                              "/packageDetails?tourid=" +
                                              Base64.btoa(TourData.id) +
                                              "&name=" +
                                              encodeURIComponent(
                                                TourData.tour_name
                                              ) +
                                              "&location=" +
                                              encodeURIComponent(
                                                TourData.tour_location
                                              ) +
                                              "&dateselected=" +
                                              encodeURIComponent(new Date())
                                            }
                                            style={{ fontSize: "14px" }}
                                            target="_blank"
                                            className="blackText font18 lineHeight22 appendBottom10 "
                                          >
                                            {TourData.tour_name.substring(
                                              0,
                                              30
                                            )}
                                          </a>
                                          <span className="redBdr appendBottom10">
                                            <em></em>
                                          </span>
                                          <div className="Small-Text">
                                            <p
                                              className="address"
                                              style={{
                                                color: "gray",
                                                fontSize: "10px",
                                              }}
                                            >
                                              {" "}
                                              {TourData.tour_location.substring(
                                                0,
                                                40
                                              )}
                                            </p>
                                          </div>
                                          <div
                                            className="containerFrom appendBottom5"
                                            style={{ display: "flex" }}
                                          >
                                            <p>
                                              <span className="font-weight-bold from-price-text">
                                                Per Group{" "}
                                              </span>
                                            </p>
                                          </div>
                                          {getAmount(
                                            specialData,
                                            Price,
                                            forMeal
                                          )}
                                          {/* <p
                                            className="blackText appendBottom5"
                                            style={{ fontSize: 10 }}
                                          >
                                            Per Group
                                          </p> */}
                                        </div>
                                      </div>
                                      <p
                                        className="SuperFlex hrtlCenter appendBottom10"
                                        style={{
                                          margin: "auto",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <span className="SuperFlex flexOne spaceBetween font16">
                                          <div className="Small-Text">
                                            <p
                                              className="address"
                                              style={{
                                                color: "gray",
                                                fontSize: "13px",
                                              }}
                                            >
                                              {" "}
                                              Offer Available From{" "}
                                              {moment().format("DD MMM Y")}{" "}
                                              *Condition Apply
                                            </p>
                                          </div>
                                        </span>
                                      </p>

                                      <div class="persuasion ">
                                        <div class="persuasion__item pc__cashbackDeal">
                                          <span class="sprite infoIconGreen"></span>
                                          <span>
                                            {item.offer_name.substring(0, 40)}
                                          </span>
                                        </div>
                                      </div>
                                      <div class="title__placeHolder font12 pc__bottomBox"></div>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          )}
                      </Slider>
                    )}{" "}
                  </>
                )}

                {this.state.transfer && (
                  <>
                    {(this.state.Experiencesdata != null &&
                      this.state.Experiencesdata.Transferspecialoffers ==
                        "no_transfer_data") ||
                    this.state.Experiencesdata.specialOffersExperiences ==
                      "no_special_offers_exp" ? (
                      <>
                        <div style={{ width: 350, margin: "0 auto" }}>
                          <img
                            src="images/noSpecialOffer.png"
                            style={{ width: "100%" }}
                          />
                          <h6 style={{ fontSize: 15, textAlign: "center" }}>
                            No Special Offer for Transfer{" "}
                          </h6>
                        </div>
                      </>
                    ) : (
                      <Slider {...settings}>
                        {this.state.Experiencesdata != null &&
                          this.state.Experiencesdata.specialOffersExperiences !=
                            "no_special_offers_exp" &&
                          this.state.Experiencesdata.specialOffersExperiences.map(
                            (item, index) => {
                              if (
                                this.state.Experiencesdata
                                  .Transferspecialoffers[index] !=
                                "no_transfer_data"
                              ) {
                                const specialData = Object.values(
                                  this.state.Experiencesdata
                                    .Transferspecialoffers[index]
                                )[0][item.tour_id].specialOffer;
                                const Price = Object.values(
                                  this.state.Experiencesdata
                                    .Transferspecialoffers[index]
                                )[0][item.tour_id].tour_price;
                                const forMeal = Object.values(
                                  this.state.Experiencesdata
                                    .Transferspecialoffers[index]
                                )[0][item.tour_id].forMeal;
                                const TourData = Object.values(
                                  this.state.Experiencesdata
                                    .Transferspecialoffers[index]
                                )[0][item.tour_id].tour_data;
                                return (
                                  <div
                                    className="slideItem"
                                    style={{ display: "inline-block" }}
                                  >
                                    <div className="SuperFlex column itemCard soCardHeight pointer">
                                      <div className="SuperFlex cardInnerInfo">
                                        <div className="itemThumb SuperFlex column hrtlCenter appendRight20">
                                          <div className="itemImgWrap makeRelative">
                                            <a
                                              href={
                                                "/transferDetails?tourid=" +
                                                Base64.btoa(TourData.id) +
                                                "&name=" +
                                                encodeURIComponent(
                                                  TourData.tour_name
                                                ) +
                                                "&location=" +
                                                encodeURIComponent(
                                                  TourData.tour_location
                                                ) +
                                                "&dateselected=" +
                                                encodeURIComponent(new Date())
                                              }
                                              target="_blank"
                                            >
                                              <img
                                                src={
                                                  this.state.Experiencesdata
                                                    .ExperiencesImage[index][0]
                                                }
                                                alt=""
                                              />
                                            </a>
                                          </div>
                                        </div>
                                        <div className="itemDesc latoBlack">
                                          <a
                                            href={
                                              "/transferDetails?tourid=" +
                                              Base64.btoa(TourData.id) +
                                              "&name=" +
                                              encodeURIComponent(
                                                TourData.tour_name
                                              ) +
                                              "&location=" +
                                              encodeURIComponent(
                                                TourData.tour_location
                                              ) +
                                              "&dateselected=" +
                                              encodeURIComponent(new Date())
                                            }
                                            style={{ fontSize: "14px" }}
                                            target="_blank"
                                            className="blackText font18 lineHeight22 appendBottom10 "
                                          >
                                            {TourData.tour_name.substring(
                                              0,
                                              30
                                            )}
                                          </a>
                                          <span className="redBdr appendBottom10">
                                            <em></em>
                                          </span>
                                          <div className="Small-Text">
                                            <p
                                              className="address"
                                              style={{
                                                color: "gray",
                                                fontSize: "10px",
                                              }}
                                            >
                                              {" "}
                                              {TourData.tour_location.substring(
                                                0,
                                                40
                                              )}
                                            </p>
                                          </div>
                                          <div
                                            className="containerFrom appendBottom5"
                                            style={{ display: "flex" }}
                                          >
                                            <p>
                                              <span className="font-weight-bold from-price-text">
                                                Per Person{" "}
                                              </span>
                                            </p>
                                          </div>
                                          {getAmount(
                                            specialData,
                                            Price,
                                            forMeal
                                          )}
                                          {/* <p
                                            className="blackText appendBottom5"
                                            style={{ fontSize: 10 }}
                                          >
                                            Per Group
                                          </p> */}
                                        </div>
                                      </div>
                                      <p
                                        className="SuperFlex hrtlCenter appendBottom10"
                                        style={{
                                          margin: "auto",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <span className="SuperFlex flexOne spaceBetween font16">
                                          <div className="Small-Text">
                                            <p
                                              className="address"
                                              style={{
                                                color: "gray",
                                                fontSize: "13px",
                                              }}
                                            >
                                              {" "}
                                              Offer Available From{" "}
                                              {moment().format("DD MMM Y")}{" "}
                                              *Condition Apply
                                            </p>
                                          </div>
                                        </span>
                                      </p>

                                      <div class="persuasion ">
                                        <div class="persuasion__item pc__cashbackDeal">
                                          <span class="sprite infoIconGreen"></span>
                                          <span>
                                            {item.offer_name.substring(0, 40)}
                                          </span>
                                        </div>
                                      </div>
                                      <div class="title__placeHolder font12 pc__bottomBox"></div>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          )}
                      </Slider>
                    )}{" "}
                  </>
                )}

                {this.state.liveaboard && (
                  <>
                    {(this.state.Experiencesdata != null &&
                      this.state.Experiencesdata.LiveAboardspecialoffers ==
                        "no_liveaboard_data") ||
                    this.state.Experiencesdata.specialOffersExperiences ==
                      "no_special_offers_exp" ? (
                      <>
                        <div style={{ width: 350, margin: "0 auto" }}>
                          <img
                            src="images/noSpecialOffer.png"
                            style={{ width: "100%" }}
                          />
                          <h6 style={{ fontSize: 15, textAlign: "center" }}>
                            No Special Offer for LiveAboard{" "}
                          </h6>
                        </div>
                      </>
                    ) : (
                      <Slider {...settings}>
                        {this.state.Experiencesdata != null &&
                          this.state.Experiencesdata.specialOffersExperiences !=
                            "no_special_offers_exp" &&
                          this.state.Experiencesdata.specialOffersExperiences.map(
                            (item, index) => {
                              if (
                                this.state.Experiencesdata
                                  .LiveAboardspecialoffers[index] !=
                                "no_liveaboard_data"
                              ) {
                                const specialData = Object.values(
                                  this.state.Experiencesdata
                                    .LiveAboardspecialoffers[index]
                                )[0][item.tour_id].specialOffer;
                                const Price = Object.values(
                                  this.state.Experiencesdata
                                    .LiveAboardspecialoffers[index]
                                )[0][item.tour_id].tour_price;
                                const forMeal = Object.values(
                                  this.state.Experiencesdata
                                    .LiveAboardspecialoffers[index]
                                )[0][item.tour_id].forMeal;
                                const TourData = Object.values(
                                  this.state.Experiencesdata
                                    .LiveAboardspecialoffers[index]
                                )[0][item.tour_id].tour_data;
                                return (
                                  <div
                                    className="slideItem"
                                    style={{ display: "inline-block" }}
                                  >
                                    <div className="SuperFlex column itemCard soCardHeight pointer">
                                      <div className="SuperFlex cardInnerInfo">
                                        <div className="itemThumb SuperFlex column hrtlCenter appendRight20">
                                          <div className="itemImgWrap makeRelative">
                                            <a
                                              href={
                                                "/liveaboardDetails?tourid=" +
                                                Base64.btoa(TourData.id) +
                                                "&name=" +
                                                encodeURIComponent(
                                                  TourData.tour_name
                                                ) +
                                                "&location=" +
                                                encodeURIComponent(
                                                  TourData.tour_location
                                                ) +
                                                "&dateselected=" +
                                                encodeURIComponent(new Date())
                                              }
                                              target="_blank"
                                            >
                                              <img
                                                src={
                                                  this.state.Experiencesdata
                                                    .ExperiencesImage[index][0]
                                                }
                                                alt=""
                                              />
                                            </a>
                                          </div>
                                        </div>
                                        <div className="itemDesc latoBlack">
                                          <a
                                            href={
                                              "/liveaboardDetails?tourid=" +
                                              Base64.btoa(TourData.id) +
                                              "&name=" +
                                              encodeURIComponent(
                                                TourData.tour_name
                                              ) +
                                              "&location=" +
                                              encodeURIComponent(
                                                TourData.tour_location
                                              ) +
                                              "&dateselected=" +
                                              encodeURIComponent(new Date())
                                            }
                                            style={{ fontSize: "14px" }}
                                            target="_blank"
                                            className="blackText font18 lineHeight22 appendBottom10 "
                                          >
                                            {TourData.tour_name.substring(
                                              0,
                                              30
                                            )}
                                          </a>
                                          <span className="redBdr appendBottom10">
                                            <em></em>
                                          </span>
                                          <div className="Small-Text">
                                            <p
                                              className="address"
                                              style={{
                                                color: "gray",
                                                fontSize: "10px",
                                              }}
                                            >
                                              {" "}
                                              {TourData.tour_location.substring(
                                                0,
                                                40
                                              )}
                                            </p>
                                          </div>
                                          <div
                                            className="containerFrom appendBottom5"
                                            style={{ display: "flex" }}
                                          >
                                            <p>
                                              <span className="font-weight-bold from-price-text">
                                                Per Group{" "}
                                              </span>
                                            </p>
                                          </div>
                                          {getAmount(
                                            specialData,
                                            Price,
                                            forMeal
                                          )}
                                          {/* <p
                                            className="blackText appendBottom5"
                                            style={{ fontSize: 10 }}
                                          >
                                            Per Group
                                          </p> */}
                                        </div>
                                      </div>
                                      <p
                                        className="SuperFlex hrtlCenter appendBottom10"
                                        style={{
                                          margin: "auto",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <span className="SuperFlex flexOne spaceBetween font16">
                                          <div className="Small-Text">
                                            <p
                                              className="address"
                                              style={{
                                                color: "gray",
                                                fontSize: "13px",
                                              }}
                                            >
                                              {" "}
                                              Offer Available From{" "}
                                              {moment().format("DD MMM Y")}{" "}
                                              *Condition Apply
                                            </p>
                                          </div>
                                        </span>
                                      </p>
                                      <div class="persuasion ">
                                        <div class="persuasion__item pc__cashbackDeal">
                                          <span class="sprite infoIconGreen"></span>
                                          <span>
                                            {item.offer_name.substring(0, 40)}
                                          </span>
                                        </div>
                                      </div>
                                      <div class="title__placeHolder font12 pc__bottomBox"></div>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          )}
                      </Slider>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="appendBottom20 mb-5">
          <div className="container">
            <div className="buildBackgroundImg BuildlandingCard ">
              <div className="wid-pages">
                <h2>
                  Build your Own Trip: Trips made easier for everyone,
                  everywhere
                </h2>
                <p>
                  Create fully customized day by day itinerary of places to stay
                  and things to do, transfers during your Seychelles Travels.
                  You can save your trip plan so you can keep working on it
                  later or finalized it and book your trip.
                </p>
                {this.state?.loginData != null ? (
                  <>
                    {" "}
                    <a
                      className="btn_1 rounded"
                      //onClick={this.openOfferModal}
                      href={`${Tripurl}/hstotriplogin/${btoa(
                        this.state.loginData?.email
                      )}`}
                      style={{
                        marginTop: 25,
                        marginLeft: "35%",
                        fontSize: "20px",
                        fontWeight: 600,
                      }}
                    >
                      Start Planning
                    </a>
                    
                  </>
                ) : (
                  <>
                    {" "}
                    <a
                      className="btn_1 rounded"
                      onClick={this.openModal}
                      // onClick={this.openOfferModal}
                      //href={`${Tripurl}/makebuildtrip/`}
                      style={{
                        marginTop: 25,
                        marginLeft: "35%",
                        fontSize: "20px",
                        fontWeight: 600,
                      }}
                    >
                      Start Planning
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <Modal
            isOpen={this.state.modalIsOfferOpen}
            onRequestClose={this.closeOfferModal}
            style={this.customStyles}
            contentLabel="Example Modal"
          >
            <div className="modal-content">
              <div className="text-right">
                <button
                  onClick={this.closeOfferModal}
                  className="close pull-right"
                >
                  <span aria-hidden="true" style={{ margin: "10px" }}>
                    &times;
                  </span>
                </button>
              </div>

              <div
                style={{
                  backgroundColor: "#fff",
                  padding: '11px',
                  margin: '0px 0px 15px'
                }}
              >
                <div className="container" style={{ border: '1px solid', padding: '11px', borderRadius: '7px', borderColor: '#c6a54d' }}>
                  <p className="text" style={{ fontSize: '18px', fontWeight: '500' }}>
                    Build “My Trip” APP or ‘The Trip Builder’ works with all the
                    other APPs together. Once we have all the experience providers
                    listed. We will activate this APP. Thank you for your
                    patience.
                  </p>

                </div>
              </div>
            </div>
          </Modal> */}

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={this.customStyles}
          contentLabel="Example Modal"
          className="LoginModal"
        >
          <div className={this.state.ChangeLoginScreenClass}>
            <div className="form-container sign-up-container">
              <form className="FormCon">
                <h1>Create Account</h1>
                <SignUp
                  country_code={this.state.country_code}
                  currency={this.state.currency}
                  ip_address={this.state.ip_address}
                  country={this.state.country}
                />
              </form>
            </div>
            {this.state.facebookLogin && (
              <FacebookLogin
                //appId="426993597895758"
                appId="1194539465165185"
                autoLoad={true}
                fields="first_name, last_name, email, picture, birthday,gender"
                scope="public_profile, email, user_birthday,user_gender"
                returnScopes={true}
                callback={this.responseFacebook}
              />
            )}
            {/* {this.state.GoogleLoginButton &&
                  
                        } */}
            <div className="form-container sign-in-container">
              <form className="FormCon">
                <h1>LOG IN</h1>
                {this.state.errorPassword && (
                  <p className="error"> Email or Password is not correct !</p>
                )}
                <input
                  type="email"
                  placeholder="Email"
                  value={this.state.LoginEmail ? this.state.LoginEmail : ""}
                  onChange={this.ChangeLoginEmail}
                />
                {this.state.EmailError && (
                  <p className="error"> Please enter a valid Email ID!</p>
                )}
                <input
                  type="password"
                  placeholder="Password"
                  value={this.state.LoginPassword}
                  onChange={this.ChangeLoginPassword}
                />
                {this.state.PasswordLoginError && (
                  <p className="error">
                    {" "}
                    The password that you've entered is incorrect
                  </p>
                )}

                <a onClick={this.openSecondModal}>Forgot your password?</a>
                <button onClick={this.LoginConfirm}>LOG IN</button>
                <div className="SocialmakeFlex socialBtnContainer vrtlCenter">
                  <div
                    className="SocialmakeFlex fbLoginBtn flexOne"
                    onClick={this.LoginFaceBook}
                  >
                    <span className=" popupSprite popupFbIcon"></span>
                    <span
                      className="font14 hs-white latoBold latoBold"
                      style={{ textTransform: "uppercase" }}
                    >
                      Login with Facebook
                    </span>
                  </div>
                </div>
                <div className="SocialmakeFlex socialBtnContainer vrtlCenter">
                  <div className="SocialmakeFlex googleLoginBtn flexOne">
                    <span style={{ width: "100%" }}>
                      {" "}
                      <GoogleLogin
                        clientId="445734814791-opepjrj50d5gdimufcicecofrl7rvb90.apps.googleusercontent.com"
                        buttonText="Login with Google"
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                        cookiePolicy={"single_host_origin"}
                      />
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div className="overlay-container">
              <div className="overlay">
                <div className="overlay-panel overlay-left">
                  <h1>Welcome Back!</h1>
                  <p>
                    To keep connected with us please login with your personal
                    info
                  </p>
                  <button className="ghost" onClick={this.ChangeLoginReverse}>
                    LOG IN
                  </button>
                </div>
                <div className="overlay-panel overlay-right">
                  <h1>Hello, Friend!</h1>
                  <p>Enter your personal details and start journey with us</p>
                  <button className="ghost" onClick={this.ChangeLoginScreen}>
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.modalIsSecondOpen}
          onRequestClose={this.closeSecondModal}
          style={this.customStyles}
          contentLabel="Example Modal"
        >
          <div className={this.state.ChangeLoginScreenClass}>
            <div className="form-container sign-in-container">
              <form className="FormCon">
                <h1>Fogot Password</h1>
                {this.state.errorForgotEmail && (
                  <p className="error"> Email is not correct !</p>
                )}
                <input
                  type="email"
                  placeholder="Email"
                  value={this.state.ForgotEmail}
                  onChange={this.ChangeForgotEmail}
                />
                {this.state.ForgotEmailError && (
                  <p className="error"> Email is not valid !</p>
                )}
                <button onClick={this.ForgotConfirm}>Submit</button>
              </form>
            </div>
            <div className="overlay-container">
              <div className="overlay">
                <div className="overlay-panel overlay-right">
                  <h1>Welcome Back!</h1>
                  <p>
                    To keep connected with us please login with your personal
                    info
                  </p>
                  <button className="ghost" onClick={this.modalIsOpen}>
                    LOG IN
                  </button>
                </div>
                {/* <div className="overlay-panel overlay-right">
                  <h1>Hello, Friend!</h1>
                  <p>Enter your personal details and start journey with us</p>
                  <button className="ghost" onClick={this.ChangeLoginScreen}>
                    Sign Up
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
